<template>
  <div class="ot-subscriber" @dblclick="onDoubleClick"></div>
</template>

<script>
import OT from '@opentok/client'
import screenfull from 'screenfull'
import { EventBus, EventsType } from '@services/eventbus.js'

export default {
  name: 'OtSubscriber',
  props: {
    stream: {
      type: OT.Stream,
      required: true,
    },
    session: {
      type: OT.Session,
      required: true,
    },
    opts: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    subscriber: null,
  }),
  mounted() {
    this.subscriber = this.session.subscribe(
      this.stream,
      this.$el,
      this.opts,
      (err) => {
        if (err) {
          this.$emit('error', err)
        } else {
          this.$emit('subscriberConnected', this.subscriber)
        }
      }
    )

    this.$emit('subscriberCreated', this.subscriber)

    EventBus.$on(EventsType.REQUEST_FRAME, () => {
      this.getStreamFrame()
    })
  },

  beforeDestroy() {
    EventBus.$off(EventsType.REQUEST_FRAME)
  },

  methods: {
    onDoubleClick() {
      if (!this.stream) return

      if (screenfull.isEnabled) {
        screenfull.toggle(this.$el)
      }
    },

    getStreamFrame() {
      if (this.stream.videoType !== 'camera' || !this.stream.hasVideo) {
        console.info('No camera or no video')
        EventBus.$emit(EventsType.ERR_NO_VIDEO)
        return
      }

      const data = this.subscriber.getImgData()

      EventBus.$emit(EventsType.IMG_DATA, {
        ...this.stream.videoDimensions,
        data,
      })

      console.info('Emitted', {
        ...this.stream.videoDimensions,
        data,
      })
    },
  },
}
</script>

<style lang="scss">
</style>
