import { curry, identity, noop, get as _get, isEqual, negate } from 'lodash'

/**
 * Tests a predicate against the parameter and
 * either runs the whenTrue or the whenFalse function.
 * Returns the result
 */
export const ifElse = curry(
  (predicate, whenTrue = noop, whenFalse = noop) => (a) =>
    predicate(a) ? whenTrue(a) : whenFalse(a)
)

/**
 * When the predicate is true returns the result of running the function.
 * Otherwise the parameter is returned as is.
 */
export const when = curry((predicate, whenTrue) => {
  return ifElse(predicate, whenTrue, identity)
})

/**
 * Takes a function that would take a node compatible callback (err, data) => ...
 * and renders it a Promise.
 * @param {Function} fn
 * @returns
 */
export const promiseFromCallback = (fn) =>
  new Promise((resolve, reject) => {
    fn((err, data) => {
      if (err) reject(err)
      resolve(data)
    })
  })


/**
 * Accepts a converging function and a list of branching functions and returns
 * a new function. When invoked, this new function is applied
 * to some arguments, and each branching function is applied to those same
 * arguments. The results of each branching function are passed as arguments
 * to the converging function to produce the return value.
 *
 * const average = converge(divide, [sum, length])
 * average([1, 2, 3, 4, 5, 6, 7]) //=> 4
 */
export const converge = (fn, fns) => (target) => fn(fns.map((f) => f(target)))

/**
 * Creates a composable filter function.
 * const isOdd = filter(x => x%2)
 * isOdd([1,2,3,4]) /// 1,3
 */
export const filter = curry((fn, arr) => arr.filter(fn))

/**
 * Complement of lodash isEqual returns true if they are different
 */
export const isDifferent = negate(isEqual);

/*
 * Curried get flow compatible
 */
export const get = curry((path, target) => _get(target, path))


/**
 * Applies a function at index in array and returns a new array.
 */
export const adjust = curry((index, fn, arr) => {
  const before = arr.slice(0, index);
  const current = arr.slice(index, index + 1).map(value => fn(value));
  const after = arr.slice(index + 1);
  return before.concat(current, after)
})

/**
 * Tries to execute a function and if successful returns the result. Otherwise returns the target as is.
 */
export const attempt = curry((fn, target) => {
  try {
    return fn(target)
  } catch (error) {
    return target
  }
})
