<template>
  <v-card class="fill-height" style="position: relative">
    <SubscriberVideo
      v-for="stream in streams"
      :key="stream.streamId"
      :ref="stream.streamId"
      :session="session"
      :stream="stream"
    />
  </v-card>
</template>

<script>
/* eslint-disable */
import OT from '@opentok/client'
import OtSubscriber from '@components/opentok/ot-subscriber.vue'
import SubscriberVideo from '@components/sessions/inc/SubscriberVideo.vue'

export default {
  name: 'CustomerSubscriber',
  components: { OtSubscriber, SubscriberVideo },
  props: {
    session: {
      type: OT.Session,
      required: true,
      default() {
        return {}
      },
    },
    streams: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
}
</script>

<style scoped lang="scss">
</style>
