<template>
  <div class="public-session-video fill-height">
    <PublicTitleBar />
    <v-container
      v-if="canDisplaySession"
      fluid
      :class="[
        'fill-height d-flex align-start',
        { mobile: !$vuetify.breakpoint.lgAndUp },
      ]"
      style="position: relative"
    >
      <v-row
        :justify="$vuetify.breakpoint.lgAndUp ? 'center' : 'start'"
        align="start"
        :style="{ height: $vuetify.breakpoint.lgAndUp ? '50%' : '100%' }"
        :no-gutters="!$vuetify.breakpoint.lgAndUp"
      >
        <v-col
          :class="[`col-auto`]"
          style="z-index: 5; height: 100%"
          :cols="12"
          :sm="12"
          :md="12"
          :lg="6"
        >
          <v-sheet
            :class="[
              'publisher-container',
              { creating: isCreatingPublisher, created: isPublisherCreated },
            ]"
            color="transparent"
            elevation="0"
            height="100%"
            width="100%"
          >
            <v-progress-circular
              v-if="!isPublisherCreated"
              class="spinner"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <CustomerPublisher
              v-if="session"
              :session="session"
              :settings="settings"
              show-controls
              class="fill-height customer-publisher"
              :is-supported-browser="isSupportedBrowser"
              @beforeCreate="isCreatingPublisher = true"
              @complete="
                isCreatingPublisher = false
                isPublisherCreated = true
              "
              @published="addStream"
              @unpublished="removeStream"
              @error="errorHandler"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row
        :justify="$vuetify.breakpoint.lgAndUp ? 'center' : 'start'"
        align="start"
        :style="{ height: $vuetify.breakpoint.lgAndUp ? '50%' : '20%' }"
      >
        <v-col
          class="col-auto"
          style="z-index: 5; height: 100%"
          :cols="4"
          :sm="4"
          :md="4"
          :lg="6"
        >
          <v-sheet
            :class="[
              'subscriber-container',
              { creating: isCreatingPublisher, created: isPublisherCreated },
            ]"
            color="transparent"
            elevation="0"
            height="100%"
            width="100%"
          >
            <v-progress-circular
              v-if="!isPublisherCreated"
              class="spinner"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <CustomerSubscriber
              v-if="session"
              v-show="settings.agent_video && visibleStreams.length"
              :session="session"
              :settings="settings"
              :streams="subscriberStreams"
              show-controls
            />
          </v-sheet>
        </v-col>
      </v-row>

      <SessionChat
        v-if="settings.enable_chat && session"
        :session="session"
        :enable-attachments="settings.enable_attachments"
        sender="Cliente"
      />
    </v-container>
    <v-card v-else class="fill-height d-flex align-stretch">
      <v-card-text class="d-flex flex-column justify-space-between">
        <v-row align="center" justify="center" style="flex-grow: 1000">
          <v-col :cols="12" :md="10" :lg="8">
            <h2> Benvenuto </h2>
            <p class="mb-10">
              Prima di procedere ti chiediamo di prendere visione ed accettare
              le condizioni d'uso del servizio.
            </p>
            <div class="tecBox text-left">
              <h3>Termini e Condizioni di servizio</h3>
              <div
                class="tec"
                style="max-height: 500px; overflow-y: auto; overflow-x: hidden"
                v-html="settings.disclaimer_text"
              >
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="mt-8" dense>
          <v-col
            :cols="12"
            :md="5"
            :lg="4"
            :order="1"
            :order-md="2"
            :order-lg="2"
          >
            <v-btn
              color="success"
              large
              block
              depressed
              @click="userAccept = true"
            >
              Accetto
            </v-btn>
          </v-col>
          <v-col
            :cols="12"
            :md="5"
            :lg="4"
            :order="2"
            :order-md="1"
            :order-lg="1"
          >
            <v-btn large block depressed :to="{ name: 'session_declined' }">
              Rifiuto
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import OT from '@opentok/client'
import SessionChat from '@components/sessions/SessionChat.vue'
import CustomerPublisher from '@components/sessions/inc/CustomerPublisher.vue'
import CustomerSubscriber from '@components/sessions/inc/CustomerSubscriber.vue'
import NetworkTest from 'opentok-network-test-js'
import {
  isChrome,
  isChromium,
  isEdgeChromium,
  isFirefox,
  isOpera,
  isSafari,
} from 'mobile-device-detect'
import {
  connectSession,
  createSession,
  isPublisherStream,
  isSubscriberStream,
  isVisible,
} from '@utils/session'
import { mapActions } from 'vuex'
import { identity, flow, property } from 'lodash'
import { projectCustomComponent } from '@/src/utils/project'

export default {
  name: 'PublicSessionVideo',
  components: {
    CustomerSubscriber,
    CustomerPublisher,
    SessionChat,
    PublicTitleBar: () => projectCustomComponent('PublicTitleBar.vue'),
  },
  props: {
    project: {
      type: String,
      default: null,
    },
    sid: {
      type: String,
      default: null,
    },
    resumed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    errors: {
      browser: false,
      permissions: false,
    },
    fullscreen: false,
    isCreatingPublisher: false,
    isPublisherCreated: false,
    notificationSettings: {
      position: 'bottom-center',
      timeout: 5000,
    },
    session: null,
    sessionInfo: null,
    streams: [],
    stream: null,
    streamVideo: null,
    suspended: false,
    userAccept: false,
  }),
  computed: {
    canDisplaySession() {
      return this.isDoneWithTheDisclaimer || this.sessionIsResumed
    },
    isDoneWithTheDisclaimer() {
      let is = false
      if (this.settings.disclaimer_show) {
        if (this.userAccept) is = true
      } else {
        is = true
      }
      return is
    },
    sessionIsResumed() {
      return !!this.$route.params.resumed
    },
    settings() {
      return this.sessionInfo?.settings || {}
    },
    // Your stream has a publisher prop to it
    publisherStreams() {
      return this.streams.filter(isPublisherStream)
    },
    // Other people's stream don't have a publisher prop
    subscriberStreams() {
      return this.streams.filter(isSubscriberStream)
    },
    visibleStreams() {
      return this.subscriberStreams.filter(isVisible)
    },
    isSupportedBrowser() {
      return (
        isChrome ||
        isChromium ||
        isEdgeChromium ||
        isFirefox ||
        isOpera ||
        isSafari
      )
    },
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  async beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
    await this.waitForSessionToBeClear()
    if (this.session?.isConnected()) this.session.disconnect()
  },
  async mounted() {
    // Try getting necessary info
    this.sessionInfo = await this.getSessionInfo({
      project: this.project,
      sid: this.sid,
    })
    // If it fails it is likely the session is expired
    if (!this.sessionInfo) {
      return this.navigateToSessionExpired()
    }

    // User is cleared when he accepts t&c or resumes a session
    await this.waitForUserToBeAllowed()
    try {
      const initialized = await this.initializeSession()
      this.session = await this.connectSession(initialized)
      this.streams = this.session.streams.where(identity)
      // This will only fire for streams from here on
      this.session.on(
        'streamCreated',
        flow([property('stream'), this.addStream])
      )
      this.session.on(
        'streamDestroyed',
        flow([property('stream'), this.removeStream])
      )
    } catch (error) {
      /**
       * The test only checks the user can connect to OT
       * so it only makes sense if something is wrong
       */
      await this.networkTest()
      console.error(error)
    }
    this.checkSupportedBrowser()
  },
  methods: {
    ...mapActions('sessions', ['getSessionInfo']),
    beforeWindowUnload(e) {
      if (
        !window.confirm('Attenzione, vuoi abbandonare la sessione in corso?')
      ) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    navigateToSessionExpired() {
      this.$router.push({ name: 'session_expired' })
    },

    /**
     * This resolves once the user is cleared to display the session
     */
    waitForUserToBeAllowed() {
      return new Promise((resolve, reject) => {
        this.interval = setInterval(() => {
          if (this.canDisplaySession) {
            clearInterval(this.interval)
            resolve(true)
          }
        }, 200)
      })
    },

    /**
     * This creates a session which is NOT connected
     */
    initializeSession() {
      const { api_key, ot_session_id } = this.sessionInfo
      return createSession(api_key, ot_session_id)
    },
    /**
     * This tells the session to connect to OT
     */
    connectSession(session) {
      const { token } = this.sessionInfo
      return connectSession(token, session)
    },

    addStream(stream) {
      this.streams = [...this.streams, stream]
    },
    removeStream(stream) {
      this.streams = this.streams.filter((s) => s.id !== stream.id)
    },

    /**
     * Waits until there are no more streams.
     * Meaning that customer has unpublished his streams.
     */
    waitForSessionToBeClear() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          this.$nextTick(() => {
            if (!this.streams.length) {
              clearInterval(interval)
              resolve(true)
            }
          })
        }, 500)
      })
    },

    /**
     * Tests if the user can connect to OT servers
     */
    async networkTest() {
      const {
        api_key: apiKey,
        ot_session_id: sessionId,
        token,
      } = this.sessionInfo
      try {
        const otNetworkTest = new NetworkTest(OT, {
          apiKey,
          sessionId,
          token,
        })

        const result = await otNetworkTest.testConnectivity()
        if (!result.success) throw new Error('connectivity error')
        return true
      } catch (err) {
        console.warn('networkTest error', err)
        this.$sentry.captureException(err)

        this.$dialog.notify.warning(
          'Attenzione, rilevati problemi di connessione. Verificare connettività o la presenza di firewall.',
          this.notificationSettings
        )
      }
    },

    errorHandler(err) {
      console.warn(err)
    },
    checkSupportedBrowser() {
      if (!this.isSupportedBrowser)
        this.$dialog.notify.warning(
          'Attenzione, il browser in uso non è tra quelli supportati. La soluzione potrebbe non funzionare correttamente.',
          this.notificationSettings
        )
    },
  },
}
</script>

<style scoped lang="scss">
.public-session-video {
  .mobile {
    padding: 0px;

    .publisher-container {
      margin-top: 0px;
      padding: 0px;
    }

    .row:nth-child(2) {
      position: absolute;
      width: 100%;
      top: 10px;
      left: 20px;
      margin-top: 0px;

      > [class*='col'] {
        padding: 0px;

        .subscriber-container {
          margin-top: 0px;
        }
      }
    }
  }
  .publisher-container {
    margin-top: 10px;
    margin-right: 100px;
    padding: 5px;
    transform: scale(0);
    transition: transform 0.5s;

    &.creating,
    &.created {
      transform: scale(1);
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .subscriber-container {
    margin-top: 10px;
    margin-right: 100px;
    padding: 5px;
    transform: scale(0);
    transition: transform 0.5s;

    &.creating,
    &.created {
      transform: scale(1);
    }

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
