<template>
  <div
    class="fill-height bg-primary publisherContainer"
    @dblclick.stop="() => fullscreen('camera')"
  >
    <div
      v-show="!status.screen"
      id="camera"
      ref="camera"
      class="fill-height"
    ></div>
    <div
      v-show="status.screen"
      id="screen"
      ref="screen"
      class="fill-height"
    ></div>

    <div
      v-if="showControls"
      :class="[
        'publisherControls d-flex',
        { opacity, mobile: !$vuetify.breakpoint.mdAndUp },
      ]"
    >
      <v-btn
        class="mr-2"
        fab
        :color="status.audio ? 'primary' : 'secondary'"
        :disabled="!status.publishing"
        :title="`${status.audio ? 'Disattiva' : 'Attiva'} audio`"
        @click="toggleAudio"
      >
        <v-icon v-if="status.audio">mdi-microphone</v-icon>
        <v-icon v-else>mdi-microphone-off</v-icon>
      </v-btn>

      <v-btn
        v-if="settings.customer_video"
        fab
        class="mr-2"
        :color="status.video ? 'secondary' : 'primary'"
        :disabled="!status.publishing"
        :title="`${status.video ? 'Disattiva' : 'Attiva'} video`"
        @click="toggleVideo"
      >
        <v-icon v-if="!status.video" light>mdi-video</v-icon>
        <v-icon v-else light>mdi-video-off</v-icon>
      </v-btn>

      <v-btn
        v-if="
          settings.customer_video && videoDevices.length > 1 && status.video
        "
        fab
        class="mr-2"
        color="primary"
        :disabled="!status.publishing"
        title="Inverti Fotocamera"
        @click="switchCamera"
      >
        <v-icon>mdi-camera-switch</v-icon>
      </v-btn>

      <v-btn
        v-if="
          settings.customer_share_screen && canShareScreen && !status.screen
        "
        fab
        class="mr-2"
        color="primary"
        title="Avvia Convisione Schermo"
        :disabled="!status.publishing"
        @click="shareScreen"
      >
        <v-icon>mdi-television</v-icon>
      </v-btn>

      <v-btn
        v-if="status.screen"
        fab
        class="mr-2"
        color="secondary"
        title="Interrompi Convisione Schermo"
        :disabled="!status.publishing"
        @click="() => unpublish('screen')"
      >
        <v-icon>mdi-television-stop</v-icon>
      </v-btn>

      <v-btn
        v-if="status.permissions"
        fab
        :disabled="!status.connected"
        color="secondary"
        @click="disconnect"
      >
        <v-icon>mdi-phone-hangup</v-icon>
      </v-btn>
    </div>

    <v-overlay
      absolute
      :opacity="0.8"
      :value="!status.permissions"
      class="text-center"
    >
      <v-col>
        <h1 class="mb-3">Ops...</h1>
        <p>
          Per utilizzare la videochiamata è necessario concedere i permessi di
          acesso a camera e microfono.
        </p>
        <p>
          Accedi alle impostazioni del browser e imposta le autorizzazioni
          necessarie.
        </p>
      </v-col>
    </v-overlay>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import OT from '@opentok/client'
import {
  isMobile,
  osName,
  osVersion,
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
} from 'mobile-device-detect'
import {
  createPublisher,
  joinSession,
  leaveSession,
  promiseFromCallback,
  initialConfig,
  isOwn,
  signal,
} from '@/src/utils/session'
import { flow, property } from 'lodash'
import screenfull from 'screenfull'

const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, ms)
  })

export default {
  name: 'CustomerPublisher',
  components: {},
  props: {
    session: {
      type: OT.Session,
      required: true,
      default() {
        return {}
      },
    },
    settings: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    showControls: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
    isSupportedBrowser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      opacity: true,
      logStatusUpdates: true,
      canShareScreen: false,
      devices: [],
      notificationSettings: {
        timeout: 5000,
      },
      // Opt generation are enclosed in an helper
      // as they are a pretty static behaviour and will clutter
      opts: {
        camera: initialConfig.customer.getOptionsBySettingsForCamera(
          this.settings
        ),
        screen: initialConfig.customer.getOptionsBySettingsForScreen(
          this.settings
        ),
      },
      publishers: {
        camera: null,
        screen: null,
      },
      // Uses a proxy rather than watch so it can
      // capture the keys that have been updated
      status: new Proxy(
        initialConfig.customer.getStatusBySettings(this.settings),
        {
          get: (status, key) => status[key],
          set: (status, key, value) => {
            if (this.logStatusUpdates) {
              console.log(
                `Updating key "${key}" in status from ${status[key]} to ${value}`
              )
            }

            status[key] = value
            // It won't be able to send signal if the session
            // is disconnected so check beforehand
            if (status.connected) {
              this.sendUserStatus()
            }
            // Must return truish value
            return true
          },
        }
      ),
    }
  },
  computed: {
    videoDevices() {
      return this.devices.filter((d) => d.kind === 'videoInput')
    },
  },
  async mounted() {
    this.devices = await this.getDevices()
    this.canShareScreen = await this.checkScreenSharing()
    // This should never fire as this component is rendered when the connection is already established
    this.session.on('sessionConnected', this.onSessionConnected)
    this.session.on('sessionDisconnected', this.onSessionDisconnected)
    this.session.on('signal:cmd', this.onCommand)
    this.$emit('beforeCreate')
    await wait(2000)
    this.publishers.camera = await this.createPublisher('camera')
    this.$emit('afterCreate')
    // If publisher could not connect return
    if (!this.publishers.camera) {
      this.status.permissions = false
      return
    }

    // This fires when YOUR stream joins the session,
    // When OTHER people's streams join, it's the session object firing this event
    this.publishers.camera.on('streamCreated', this.published('camera'))
    this.publishers.camera.on('streamDestroyed', this.unpublished('camera'))
    try {
      await this.publish('camera')
      this.status.publishing = true
      this.videoReminder()
    } catch (error) {
      this.$emit('error', error)
      this.status.publishing = false
    }
    this.$emit('complete')
  },

  methods: {
    fullscreen(target) {
      if (screenfull.isEnabled) {
        screenfull.request(this.$refs[target])
      }
    },
    async getDevices() {
      try {
        const devices = await promiseFromCallback(OT.getDevices)
        return devices
      } catch (error) {
        console.error('Impossibile ottenere dispositivi', error)
        this.$dialog.notify.warning(
          'Impossibile accedere a microfono e webcam',
          this.notificationSettings
        )
      }
    },
    onSessionConnected() {
      this.status.connected = true
    },
    onSessionDisconnected() {
      this.status.connected = false
    },
    async publish(target) {
      await joinSession(this.session, this.publishers[target])
    },

    async createPublisher(template) {
      try {
        const publisher = await createPublisher(
          this.$refs[template],
          this.opts[template]
        )
        return publisher
      } catch (error) {
        console.error(error)
        if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          const errorMessages = {
            camera: 'Impossibile accedere a camera e/o microfono.',
            screen: 'Seleziona uno schermo per iniziare a condividere',
          }
          this.$dialog.notify.error(
            errorMessages[template],
            this.notificationSettings
          )
          // If the user disallow camera/mic permissions set permissions as false
          // For screen sharing do nothing
          if (template === 'camera') {
            this.status.permissions = false
          }
          // If it's an unknown error, propagate it
        } else {
          throw error
        }
      }
    },
    /** Unpublish the target, either a camera or screen sharing stream */
    async unpublish(target) {
      await leaveSession(this.session, this.publishers[target])
      if (target === 'camera') this.status.publishing = false
      if (target === 'screen') this.status.screen = false
    },

    async unpublishAll() {
      if (this.publishers.camera) await this.unpublish('camera')
      if (this.publishers.screen) await this.unpublish('screen')
      return true
    },

    /** Handles published events */
    published(template) {
      return (event) => {
        this.$emit('beforePublish')
        switch (template) {
          case 'camera':
            break
          case 'screen':
            this.$dialog.notify.success(
              'Condivisione schermo abilitata',
              this.notificationSettings
            )
            this.status.screen = true
            break
        }
        this.$emit('published', event.stream)
      }
    },

    unpublished(template) {
      return (event) => {
        this.$emit('beforeUnpublish')
        switch (template) {
          case 'camera':
            console.log(
              'The publisher stopped streaming. Reason: ' + event.reason
            )
            break
          case 'screen':
            console.log(
              'The publisher stopped streaming. Reason: ' + event.reason
            )
            this.$dialog.notify.info(
              'Condivisione schermo interrotta',
              this.notificationSettings
            )
            break
        }
        this.$emit('unpublished', event.stream)
      }
    },

    async checkScreenSharing() {
      return new Promise((resolve) => {
        OT.checkScreenSharingCapability((response) =>
          resolve(response.supported && !isMobile && this.isSupportedBrowser)
        )
      })
    },

    async shareScreen() {
      this.publishers.screen = await this.createPublisher('screen')
      // If the publisher could not be created return
      if (!this.publishers.screen) {
        return
      }

      this.publishers.screen.on('streamDestroyed', this.unpublished('screen'))

      try {
        await this.publish('screen')
        this.status.screen = true
      } catch (error) {
        this.$dialog.notify.error(
          'Errore durante la condivisione schermo',
          this.notificationSettings
        )
        console.warn('Error with screen share stream', error)
        this.$emit('error', error)
      }
    },

    async disconnect() {
      const res = await this.$dialog.confirm({
        text: `Vuoi terminare la sessione?`,
        title: 'Attenzione',
      })

      if (!res) return

      await this.unpublishAll()
      await signal(this.session, 'customerDisconnected')
      return this.$router.replace({
        name: 'session_done',
        params: { sid: this.sid, project: this.project },
      })
    },

    /** Extracts the relevant parts of an event */
    getEventData: flow([property('data'), JSON.parse]),

    async onCommand(event) {
      if (isOwn(this.session, event)) return
      const data = this.getEventData(event)

      let res = false
      switch (data.type) {
        case 'customer_video':
          res = await this.$dialog.confirm({
            text: `L'agente ha richiesto  di ${
              this.status.video ? 'disabilitare' : 'abilitare'
            }
              la tua fotocamera. Proseguire?`,
            title: 'Attenzione',
          })

          if (res) await this.toggleVideo()
          break

        case 'customer_audio':
          res = await this.$dialog.confirm({
            text: `L'agente ha richiesto  di ${
              this.status.audio ? 'disabilitare' : 'abilitare'
            }
              il tuo microfono. Proseguire?`,
            title: 'Attenzione',
          })

          if (res) await this.toggleAudio()
          break

        case 'cycle_camera':
          res = await this.$dialog.confirm({
            text:
              'Il tecnico ha richiesto il cambio di fotocamera. Proseguire?',
            title: 'Attenzione',
          })

          if (res) await this.switchCamera()
          break

        case 'session_suspend':
          await this.unpublishAll()
          return this.$router.replace({
            name: 'session_suspended',
            params: {
              sid: this.sid,
              project: this.project,
              userAccept: this.userAccept,
            },
          })

        case 'session_end':
          await this.unpublishAll()
          return this.$router.replace({
            name: 'session_done',
            params: { sid: this.sid, project: this.project },
          })

        case 'get_status':
          return this.sendUserStatus()
      }
    },

    async toggleVideo() {
      try {
        this.publishers.camera.publishVideo(!this.status.video)
        this.status.video = !this.status.video
      } catch (err) {
        console.info('toggleVideo Errror', err)
      }
    },

    async toggleAudio() {
      try {
        this.publishers.camera.publishAudio(!this.status.audio)
        this.status.audio = !this.status.audio
      } catch (err) {
        console.info('toggleVideo Errror', err)
      }
    },

    async switchCamera() {
      try {
        await this.publishers.camera.cycleVideo()
      } catch (err) {
        console.info('switchCamera error', err)
      }
    },

    async sendUserStatus() {
      const data = {
        status: this.status,
        device: {
          mobile: isMobile,
          os: osName,
          os_ver: osVersion,
          browser: browserName,
          browser_ver: browserVersion,
          vendor: mobileVendor,
          model: mobileModel,
        },
      }
      await signal(this.session, 'user_current_status', data)
    },

    videoReminder() {
      if (!this.status.video)
        this.$dialog.notify.info(
          "Abilita il tuo video affinché sia visibile all'operatore.",
          this.notificationSettings
        )
    },
  },
}
</script>

<style scoped lang="scss">
.publisherContainer {
  &:hover {
    .publisherControls {
      opacity: 1;
    }
  }

  .publisherControls {
    position: absolute;
    left: 50%;
    bottom: 16px;
    transition: opacity 0.5s;
    z-index: 100;
    transform: translateX(-50%);

    &.mobile {
      left: 16px;
      transform: initial;
    }
  }
}
</style>
